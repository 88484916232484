@charset "utf-8";

.editormd-tab {
}

.editormd-tab-head {
    list-style: none;
    border-bottom: 1px solid #ddd;

    li {
        display: inline-block;

        a {
            color: #999;
            display: block;
            padding: 6px 12px 5px;
            text-align: center;
            text-decoration: none;
            margin-bottom: -1px;
            border: 1px solid #ddd;
            @include border-top-left-radius(3px);
            @include border-top-right-radius(3px);
            background: #f6f6f6;
            @include transition(all 300ms ease-out);

            &:hover {
                color: #666;
                background: #eee;
            }
        }

        &.active a {
            color: #666;
            background: #fff;
            border-bottom-color: #fff; 
        }
    }
    
    li + li {
        margin-left: 3px;
    }
}

.editormd-tab-container {
}

.editormd-tab-box {
    padding: 20px 0;
}