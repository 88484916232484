@charset "UTF-8";

@import "lib/variables";
@import "lib/prefixes";

@font-face {
	font-family: 'editormd-logo';
	src:url('../fonts/editormd-logo.eot?-5y8q6h');
	src:url('.../fonts/editormd-logo.eot?#iefix-5y8q6h') format('embedded-opentype'),
		url('../fonts/editormd-logo.woff?-5y8q6h') format('woff'),
		url('../fonts/editormd-logo.ttf?-5y8q6h') format('truetype'),
		url('../fonts/editormd-logo.svg?-5y8q6h#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

#{$prefix}logo,
#{$prefix}logo-1x,
#{$prefix}logo-2x,
#{$prefix}logo-3x,
#{$prefix}logo-4x,
#{$prefix}logo-5x,
#{$prefix}logo-6x,
#{$prefix}logo-7x,
#{$prefix}logo-8x {
	font-family: 'editormd-logo';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
    font-size: inherit;
	line-height: 1;
    display: inline-block;
    text-rendering: auto;
    vertical-align: inherit;

	//Better Font Rendering
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

    &:before {
        content: "\e1987";
        /* 
        HTML Entity &#xe1987; 
        example: <span class="editormd-logo">&#xe1987;</span>
        */
    }
}

#{$prefix}logo-1x {  
    font-size: 1em;           
}

#{$prefix}logo-lg {  
    font-size: 1.2em;           
}

#{$prefix}logo-2x {  
    font-size: 2em;           
}

#{$prefix}logo-3x {  
    font-size: 3em;           
}

#{$prefix}logo-4x {  
    font-size: 4em;           
}

#{$prefix}logo-5x {  
    font-size: 5em;      
}

#{$prefix}logo-6x {  
    font-size: 6em;      
}

#{$prefix}logo-7x {  
    font-size: 7em;      
}

#{$prefix}logo-8x {  
    font-size: 8em;   
}

#{$prefix}logo-color {
    color: $mainColor;
}