// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.


// INFO: Colors for RMArchiv. (Classic Design)
$rm-back: #00001a;
$rm-text: #ffffe0;
$rm-link: #ffbf00;
$rm-link-hover: #ffdf00;

$rm-base-m1: #112942;
$rm-base: #17395c;
$rm-base-p1: #1a4169;
$rm-base-p2: #215285;
$rm-base-p3: #2a6bab;

$font-family-base: 0.75rem;

$enable-caret:              true;
$enable-rounded:            false;
$enable-shadows:            false;
$enable-gradients:          false;
$enable-transitions:        true;
$enable-grid-classes:       true;
$enable-print-styles:       true;

$body-bg:                   $rm-back;
$body-color:                $rm-text;

$link-color:                $rm-link;
$link-decoration:           none !default;
$link-hover-color:          $rm-link-hover;
$link-hover-decoration:     underline !default;

//Cards
$card-border-color:                 $rm-base;
$card-cap-bg:                       $rm-base-p1;
$card-bg:                           $rm-base-m1;

//Navbar
$navbar-light-color:                $rm-base;
$navbar-light-hover-color:          $rm-base-p1;
$navbar-light-active-color:         $rm-base-p1;
$navbar-light-disabled-color:       $rm-base-m1;
$navbar-light-toggler-icon-bg:      str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-light-toggler-border-color: $rm-base;

//Listgroup
$list-group-bg:                     $rm-base-m1;
$list-group-border-color:           $rm-base;

$list-group-hover-bg:               $rm-base-p1;
$list-group-active-color:           $rm-link-hover;
$list-group-active-bg:              $rm-base-p1;
$list-group-active-border-color:    $rm-base;

$list-group-action-color:           $rm-link;
$list-group-action-hover-color:     $rm-link-hover;

$list-group-action-active-color:    $rm-link;
$list-group-action-active-bg:       $rm-base-p1;

//Breadcrumbs
$breadcrumb-bg:                     $rm-base-m1 !default;
$breadcrumb-divider-color:          $rm-base-p2 !default;
$breadcrumb-active-color:           $rm-text !default;
$breadcrumb-divider:                "/" !default;