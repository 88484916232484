/* Editor.md Dark theme */

#{$prefix}theme-dark {
    border-color: #1a1a17;

    #{$prefix}toolbar {
        background: #1A1A17;
        border-color: #1a1a17;
    }

    #{$prefix}menu > li > a {
        color: #777;
        border-color: #1a1a17;

        &:hover, &.active {
            border-color: #333;
            background: #333;
        }
    }

    #{$prefix}menu > li.divider {
        border-right: 1px solid #111;
    }
    
    .CodeMirror {
        border-right: 1px solid rgba(0,0,0,0.1);
    }
}