.navbar-custom {
  background-color: $rm-base;
}

/* change the brand and text color */
.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
  color: $rm-text;
}

/* change the link color */
.navbar-custom .navbar-nav .nav-link {
  color: $rm-link;
}

/* change the color of active or hovered links */
.navbar-custom .nav-item.active .nav-link,
.navbar-custom .nav-item:hover .nav-link {
  color: $rm-link-hover;
}

/* for dropdown only - change the color of droodown */
.navbar-custom .dropdown-menu {
  background-color: $rm-base-p1;
}
.navbar-custom .dropdown-item {
  color: $rm-link;
}
.navbar-custom .dropdown-item:hover,
.navbar-custom .dropdown-item:focus {
  color: $rm-link-hover;
  background-color: $rm-base;
}