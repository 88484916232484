@charset "UTF-8";

.editormd .CodeMirror, #{$prefix}preview {
    display: inline-block;
    width: 50%;
    height: 100%;
    vertical-align: top;
    @include box-sizing(border-box);
    margin: 0;
}

#{$prefix}preview {
    position: absolute;
    top: 35px;
    right: 0;
    right: -1px\0;
    overflow: auto;
    line-height: 1.6;
    display: none;
    background: #fff;
}

.editormd {
    
    .CodeMirror {
        z-index: 10;
        float: left;
        border-right: 1px solid $borderColor;
        font-size: 14px;
        font-family: "YaHei Consolas Hybrid", Consolas, "微软雅黑", "Meiryo UI", "Malgun Gothic", "Segoe UI", "Trebuchet MS", Helvetica, "Monaco", courier, monospace;
        line-height: 1.6;
        margin-top: 35px;

		pre {
			font-size: 14px;
            padding: 0 12px;
		}
    }
    
    .CodeMirror-linenumbers {
        padding: 0 5px;
    }

    .CodeMirror-selected {
        background: #70B7FF;
    }

    .CodeMirror-focused .CodeMirror-selected { 
        background: #70B7FF; 
    }

    .CodeMirror, .CodeMirror-scroll, #{$prefix}preview {
        -webkit-overflow-scrolling : touch;
    }

    .styled-background { 
        background-color: #ff7; 
    }

    .CodeMirror-focused .cm-matchhighlight {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAFklEQVQI12NgYGBgkKzc8x9CMDAwAAAmhwSbidEoSQAAAABJRU5ErkJggg==);
        background-position: bottom;
        background-repeat: repeat-x;
    }
    
    .CodeMirror-empty { 
        //outline: 1px solid #c22;
        
        &.CodeMirror-focused { 
            outline: none;
        }
    }
    
    .CodeMirror pre.CodeMirror-placeholder { 
        color: #999;
    }

    .cm-trailingspace {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAACCAYAAAB/qH1jAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3QUXCToH00Y1UgAAACFJREFUCNdjPMDBUc/AwNDAAAFMTAwMDA0OP34wQgX/AQBYgwYEx4f9lQAAAABJRU5ErkJggg==);
        background-position: bottom left;
        background-repeat: repeat-x;
    }

    .cm-tab {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAMCAYAAAAkuj5RAAAAAXNSR0IArs4c6QAAAGFJREFUSMft1LsRQFAQheHPowAKoACx3IgEKtaEHujDjORSgWTH/ZOdnZOcM/sgk/kFFWY0qV8foQwS4MKBCS3qR6ixBJvElOobYAtivseIE120FaowJPN75GMu8j/LfMwNjh4HUpwg4LUAAAAASUVORK5CYII=);
        background-position: right;
        background-repeat: no-repeat;
    }
}