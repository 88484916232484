@import "variables";

.editormd {
  border-color: $rm-base-p1;
  border-radius: 4px;
}

.editormd-toolbar {
  background: $rm-base;
  border-bottom: 1px solid $rm-base-p1;
}

.editormd-menu li a {
  color: $rm-link;
  background-color: $rm-base;
  border-color: $rm-base-p1;
  -webkit-transition: none;
  transition: none;
}

.editormd-menu li a:focus {
  color: $rm-link-hover;
  background-color: #826200;
  border-color: #785b00;
  -webkit-transition: none;
  transition: none;
}

.editormd-menu li a:hover {
  color: $rm-link-hover;
  background-color: #826200;
  border-color: #785b00;
  -webkit-transition: none;
  transition: none;
}

.editormd .CodeMirror {
  border-right: rgba(0,0,0, 0.15);
  background-color: #A9BDBD;
  color: #073642;
}

.CodeMirror-linunumber {
  color: #839496;

}

.CodeMirror-gutters {
  background-color: $rm-base !important;
  border-right: 1px solid $rm-base-p1 !important;
}

.editormd-container-mask {
  background-color: $rm-base;
}