.type_ { background-position: -112px -160px }
.type_full { background-position: -16px -32px; }
.type_demo { background-position: -96px -16px; }
.type_techdemo { background-position: -128px -16px; }

.type_rm95 { background-position: -0px -160px }
.type_rm2k { background-position: -16px -160px }
.type_rm2k3 { background-position: -32px -160px }
.type_rmxp { background-position: -48px -160px }
.type_rmvx { background-position: -64px -160px }
.type_rmvxa { background-position: -80px -160px }
.type_rmmv { background-position:  -96px -160px }

.type_32b { background-position: -0px -0px; }
.type_64b { background-position: -16px -0px; }
.type_128b { background-position: -32px -0px; }
.type_256b { background-position: -48px -0px; }
.type_512b { background-position: -64px -0px; }
.type_1k { background-position: -80px -0px; }
.type_4k { background-position: -96px -0px; }
.type_8k { background-position: -112px -0px; }
.type_16k { background-position: -128px -0px; }
.type_32k { background-position: -144px -0px; }
.type_40k { background-position: -160px -0px; }
.type_64k { background-position: -0px -16px; }
.type_80k { background-position: -16px -16px; }
.type_96k { background-position: -32px -16px; }
.type_100k { background-position: -16px -16px; }
.type_128k { background-position: -16px -16px; }
.type_256k { background-position: -16px -16px; }
.type_artpack { background-position: -48px -16px; }
.type_bbstro { background-position: -64px -16px; }
.type_cracktro { background-position: -80px -16px; }
.type_demopack { background-position: -112px -16px; }
.type_demotool { background-position: -128px -16px; }
.type_dentro { background-position: -144px -16px; }
.type_diskmag { background-position: -160px -16px; }
.type_fastdemo { background-position: -96px -16px; }
.type_game { background-position: -0px -32px; }
.type_intro { background-position: -16px -16px; }
.type_invitation { background-position: -16px -32px; }
.type_liveact { background-position: -32px -32px; }
.type_musicdisk { background-position: -48px -32px; }
.type_procedural_graphics { background-position: -64px -32px; }
.type_report { background-position: -80px -32px; }
.type_slideshow { background-position: -96px -32px; }
.type_votedisk { background-position: -16px -32px; }
.type_wild { background-position: -32px -32px; }
.os_beos { background-position: -112px -32px; }
.os_linux { background-position: -128px -32px; }
.os_msdos { background-position: -144px -32px; }
.os_windows { background-position: -160px -32px; }
.os_msdosgus { background-position: -0px -48px; }
.os_atarist { background-position: -16px -48px; }
.os_amigaaga { background-position: -32px -48px; }
.os_atariste { background-position: -48px -48px; }
.os_amigaocsecs { background-position: -64px -48px; }
.os_java { background-position: -80px -48px; }
.os_playstation { background-position: -96px -48px; }
.os_commodore64 { background-position: -112px -48px; }
.os_wild { background-position: -128px -48px; }
.os_amstradcpc { background-position: -144px -48px; }
.os_amigappcrtg { background-position: -160px -48px; }
.os_atarifalcon030 { background-position: -0px -64px; }
.os_gameboy { background-position: -16px -64px; }
.os_zxspectrum { background-position: -32px -64px; }
.os_macosx { background-position: -48px -64px; }
.os_macos { background-position: -64px -64px; }
.os_gameboyadvance { background-position: -80px -64px; }
.os_gameboycolor { background-position: -96px -64px; }
.os_dreamcast { background-position: -112px -64px; }
.os_snessuperfamicom { background-position: -128px -64px; }
.os_segagenesismegadrive { background-position: -144px -64px; }
.os_flash { background-position: -160px -64px; }
.os_oric { background-position: -0px -80px; }
.os_mobilephone { background-position: -16px -80px; }
.os_vic20 { background-position: -32px -80px; }
.os_playstation2 { background-position: -48px -80px; }
.os_ti8x { background-position: -64px -80px; }
.os_ataritt030 { background-position: -80px -80px; }
.os_acorn { background-position: -96px -80px; }
.os_javascript { background-position: -112px -80px; }
.os_alambik { background-position: -128px -80px; }
.os_necturbografxpcengine { background-position: -144px -80px; }
.os_xbox { background-position: -160px -80px; }
.os_palmos { background-position: -0px -96px; }
.os_nintendo64 { background-position: -16px -96px; }
.os_c16116plus4 { background-position: -32px -96px; }
.os_pocketpc { background-position: -48px -96px; }
.os_php { background-position: -64px -96px; }
.os_msx { background-position: -80px -96px; }
.os_gameparkgp32 { background-position: -96px -96px; }
.os_atarixlxe { background-position: -112px -96px; }
.os_intellivision { background-position: -128px -96px; }
.os_thomson { background-position: -144px -96px; }
.os_appleiigs { background-position: -160px -96px; }
.os_segamastersystem { background-position: -0px -112px; }
.os_nesfamicom { background-position: -16px -112px; }
.os_gamecube { background-position: -32px -112px; }
.os_gameparkgp2x { background-position: -48px -112px; }
.os_atarivcs { background-position: -64px -112px; }
.os_virtualboy { background-position: -80px -112px; }
.os_bk001011m { background-position: -96px -112px; }
.os_pokemonmini { background-position: -112px -112px; }
.os_segagamegear { background-position: -128px -112px; }
.os_vectrex { background-position: -144px -112px; }
.os_ios { background-position: -160px -112px; }
.os_playstationportable { background-position: -0px -128px; }
.os_nintendods { background-position: -16px -128px; }
.os_atarijaguar { background-position: -32px -128px; }
.os_wonderswan { background-position: -48px -128px; }
.os_neogeopocket { background-position: -64px -128px; }
.os_xbox360 { background-position: -80px -128px; }
.os_atarilynx { background-position: -96px -128px; }
.os_c64dtv { background-position: -112px -128px; }
.os_amstradplus { background-position: -128px -128px; }
.os_freebsd { background-position: -144px -128px; }
.os_solaris { background-position: -160px -128px; }
.os_spectravideo3x8 { background-position: -0px -144px; }
.os_appleii { background-position: -16px -144px; }
.os_macosxintel { background-position: -32px -144px; }
.os_playstation3 { background-position: -48px -144px; }
.os_nintendowii { background-position: -64px -144px; }
.os_sgiirix { background-position: -80px -144px; }
.os_bbcmicro { background-position: -96px -144px; }
.os_msx2 { background-position: -112px -144px; }
.os_samcoup { background-position: -128px -144px; }
.os_trs80coco { background-position: -144px -144px; }
.os_msxturbor { background-position: -160px -144px; }

.face_1 {
  background-position: 0px 0px;
}

.face_2 {
  background-position: -48px 0px;
}

.face_3 {
  background-position: -96px 0px;
}

.face_4 {
  background-position: -144px 0px;
}

.face_5 {
  background-position: 0px -48px;
}

.face_6 {
  background-position: -48px -48px;
}

.face_7 {
  background-position: -96px -48px;
}

.face_8 {
  background-position: -144px -48px;
}

.face_9 {
  background-position: 0px -96px;
}

.face_10 {
  background-position: -48px -96px;
}

.face_11 {
  background-position: -96px -96px;
}

.face_12 {
  background-position: -144px -96px;
}

.face_13 {
  background-position: 0px -144px;
}

.face_14 {
  background-position: -48px -144px;
}

.face_15 {
  background-position: -96px -144px;
}

.face_16 {
  background-position: -144px -144px;
}