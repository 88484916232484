@charset "UTF-8";

// Global Variables

$prefix       : ".editormd-";
$color        : #666;
$mainColor    : #2196F3;
$primaryColor : $mainColor;
$secondColor  : #33CC66;
$thirdColor   : #999999;
$borderColor  : #ddd;